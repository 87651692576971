



































































































































































import { Component, Vue } from 'vue-property-decorator'
import {
    apiDistributionDetail,
    apiDistributionfreeze
} from '@/api/distribution/distribution'
import LsInvitationChange from '@/components/user/ls-invitation-change.vue'
import DistributeGrade from '@/components/marketing/distribute-grade.vue'
import LsDialog from '@/components/ls-dialog.vue'
@Component({
    components: {
        LsDialog,
        DistributeGrade,
        LsInvitationChange
    }
})
export default class AddSupplier extends Vue {
    /** S Data **/

    id!: any //当前的ID

    distributionData: any = {
        level_name: '',
        earningns: '',
        unreturned_commission: '',
        distribution_order_num: '',
        user_info: {
            nickname: '凉央未夜11111111111',
            sn: '58565263'
        }
    }

    /** E Data **/

    /** S Method **/

    // 获取分销商详情
    getDetailsData() {
        apiDistributionDetail({ user_id: this.id }).then((res: any) => {
            this.distributionData = { ...res }
        })
    }

    //  冻结或者解冻
    frozen(): void {
        apiDistributionfreeze({
            user_id: this.id
        }).then(() => {
            this.getDetailsData()
        })
    }

    // 跳转邀请人数页面
    toInvitationList() {
        this.$router.push({
            path: '/distribution/lower_level_list',
            query: {
                id: this.id
            }
        })
    }

    /** E Method **/

    created() {
        this.id = this.$route.query.id
        this.id && this.getDetailsData()
    }
}
